// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/AboutMe.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-animated-background-js": () => import("./../../../src/pages/AnimatedBackground.js" /* webpackChunkName: "component---src-pages-animated-background-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/Projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-say-hello-js": () => import("./../../../src/pages/SayHello.js" /* webpackChunkName: "component---src-pages-say-hello-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

