import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    
        primary: {
            light: "#ffffff",
            main: "#f5f5f5",
            dark: "#c2c2c2",
            contrastText: "#fff"
        },
        secondary: {
            light: "#a4a4a4",
            main: "#757575",
            dark: "#494949",
            contrastText: "#fff"
        },
        
    }
);